import React, { useState } from 'react';
import { useMediaQuery } from "usehooks-ts";
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Container, Image, ListGroup, Ratio, Row } from 'react-bootstrap';
import { useAccountContext } from '../../contexts/Account';
import { useAppContext } from '../../contexts/App';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';

import styles from './styles.module.scss';

const Footer = () => {
  const { appState } = useAppContext();
  const { isAuthenticated } = useAccountContext();
  const location = useLocation();
  const year = new Date().getFullYear();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 991px)');

  const [showHideSection, setShowHideSection] = useState({
    categories: false,
    categories2: false,
    company: false,
    resources: false,
  });

  const seoKeywords = [
    'E-commerce packaging',
    'Food packaging',
    'Retail packaging supplies',
    'Industrial packaging',
    'Pharmaceutical packaging',
    'Subscription boxes',
    'Export packaging',
    'Wholesale packaging',
    'Kraft paper',
    'Biodegradable materials',
    'Poly mailers',
    'Plastic packaging',
    'Metal packaging',
    'Recyclable materials',
    'Laminated packaging',
    'Minimalist packaging',
    'Product labels',
    'Packing tape',
    'Bubble wrap',
    'Stretch wrap',
    'Packing peanuts',
    'Cushioning materials',
    'Foam inserts',
    'Strapping supplies',
    'Sealing equipment',
    'Labels and stickers',
    'Void fill',
    'Cardboard boxes',
    'Shipping boxes',
    'Moving boxes',
    'Custom boxes',
    'Die-cut boxes',
    'Corrugated cardboard',
    'Folding boxes',
    'Heavy-duty boxes',
    'Decorative boxes',
    'Gift boxes',
    'Corrugated boxes',
    'Eco-friendly packaging',
    'Protective packaging',
  ];

  const toggleShowHide = (section) => {
    showHideSection[section] = !showHideSection[section];

    setShowHideSection({ ...showHideSection });
  };

  return <>
    {'/feedback' !== location.pathname ? <Button to={'/feedback'} as={Link} className={`${styles.feedback} text-white`}>
      <FontAwesomeIcon icon={'lightbulb-on'} className={'me-2'}/>
      Feedback
    </Button> : ''}
    <section className={'grey darken-3'}>
      <Container>
        <Row>
          <Col xs={12} lg={4}>
            <Row className={'my-4'}>
              <Col xs={3} md={2} lg={4} xl={3}>
                <Ratio aspectRatio={'1x1'} className={'d-flex flex-column bg-danger circle w-100'}>
                  <div className={'d-flex align-items-center justify-content-center'}>
                    <FontAwesomeIcon icon={'truck'} className={'fs-1 white-text'}/>
                  </div>
                </Ratio>
              </Col>
              <Col xs={9} md={10} lg={8} xl={9} className={'d-flex align-items-center'}>
                <div>
                  <h2 className={'fs-5 red-text text-lighten-2'}>
                    Fast 1-3 day courier service
                  </h2>
                  <p className={'fs-7 mb-0 text-white'}>
                    All orders placed by 2pm are dispatched on the same day using a 1-3 day courier service.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <hr className={'border-bottom d-block d-lg-none my-0'}/>
          <Col xs={12} lg={4}>
            <Row className={'my-4'}>
              <Col xs={3} md={2} lg={4} xl={3}>
                <Ratio aspectRatio={'1x1'} className={'d-flex flex-column bg-success circle w-100'}>
                  <div className={'d-flex align-items-center justify-content-center'}>
                    <FontAwesomeIcon icon={'shopping-cart'} className={'fs-1 white-text'}/>
                  </div>
                </Ratio>
              </Col>
              <Col xs={9} md={10} lg={8} xl={9} className={'d-flex align-items-center'}>
                <div>
                  <h2 className={'fs-5 green-text text-lighten-2'}>
                    Easy Simple Checkout
                  </h2>
                  <p className={'fs-7 mb-0 text-white'}>
                    Fast checkout with easy minimal steps required.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <hr className={'border-bottom d-block d-lg-none my-0'}/>
          <Col xs={12} lg={4}>
            <Row className={'my-4'}>
              <Col xs={3} md={2} lg={4} xl={3}>
                <Ratio aspectRatio={'1x1'} className={'d-flex flex-column bg-warning circle w-100'}>
                  <div className={'d-flex align-items-center justify-content-center'}>
                    <FontAwesomeIcon icon={'user'} className={'fs-1 white-text'}/>
                  </div>
                </Ratio>
              </Col>
              <Col xs={9} md={10} lg={8} xl={9} className={'d-flex align-items-center'}>
                <div>
                  <h2 className={'fs-5 yellow-text text-lighten-2'}>
                    Easy Account Management
                  </h2>
                  <p className={'fs-7 mb-0 text-white'}>
                    Easy and simple to use account area, manage orders, invoices, and much more...
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
    <footer className={`${styles.footer} grey lighten-5`}>
      <Container>
        <Row className="pt-5 pb-4">
          <Col xs={12} md={12} lg={4} className="fs-8 mb-4">
            <Link to={'/'}>
              <Image src={`${process.env.REACT_APP_HOST_CDN}/images/logo.png`} width={'60%'} alt="Wholesale Packaging"/>
            </Link>
            <p className="mt-4">
              {year} © Wholesale Packaging Ltd<br/>
              Founded 2004
            </p>
            <p className="mb-3 grey-text text-darken-1">
              <small>
                Wholesale Packaging Ltd t/a Wholesale Packaging.<br/>
                Registered in England & Wales.<br/>
                a UK company based in Elstree.
              </small>
            </p>
            <p className={('yes' === process.env.REACT_APP_VAT_ENABLED ? 'mb-1' : 'mb-3') + ' text-grey'}>
              <small className={'grey-text bold'}>
                <span className="grey-text text-darken-4 me-1">Reg No.</span>
                {process.env.REACT_APP_REG_NO}
              </small>
            </p>
            {'yes' === process.env.REACT_APP_VAT_ENABLED ? <p className="mb-3 text-grey">
              <small className={'grey-text bold'}>
                <span className="grey-text text-darken-4 me-1">VAT No.</span>
                {process.env.REACT_APP_VAT_NO}
              </small>
            </p> : ''}
            <p className="mb-1">
              <span className="me-2 text-secondary">
                <FontAwesomeIcon icon="phone" className="me-1"/>
                Call
              </span>
              +44(0)208 386 6960
            </p>
            <p className="mb-4">
              <span className="me-2 text-secondary">
                <FontAwesomeIcon icon="envelope" className="me-1"/>
                Enquiries
              </span>
              info@wholesalepackaging.co.uk
            </p>
            <p className="mb-1">
              <Image src={`${process.env.REACT_APP_HOST_CDN}/images/gateways/3dsecure.png`} height={20}/>
            </p>
          </Col>
          <Col xs={12} md={6} lg={true}>
            <h5 className="text-secondary fs-6">
              <FontAwesomeIcon icon="box" className="text-secondary me-2" />
              Categories
              {isMobile ? <Button
                onClick={() => toggleShowHide('categories')}
                variant={'link'}
                size={'sm'}
                className={'ms-4 transparent light border-none'}
              >
                <FontAwesomeIcon icon={showHideSection.categories ? 'chevron-up' : 'chevron-down'} className={'grey-text text-darken-3'}/>
              </Button> : ''}
            </h5>
            {(isMobile && showHideSection.categories) || !isMobile ? <ListGroup variant="flush">
              {0 < appState.menu.length ? appState.menu.slice(0, 6).map((parent, pIdx) => <ListGroup.Item
                key={`footer_category_first_${pIdx}`}
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={`/c/${parent.slug}`}
              >
                {parent.name}
              </ListGroup.Item>) : ''}
            </ListGroup> : ''}
          </Col>
          <Col xs={12} md={6} lg={true} className="mb-4">
            <h5 className="text-secondary d-none d-md-block fs-6">
              &nbsp;
            </h5>
            {(isMobile && showHideSection.categories) || !isMobile ? <ListGroup variant="flush">
              {0 < appState.menu.length ? appState.menu.slice(6, 12).map((parent, pIdx) => <ListGroup.Item
                key={`footer_category_second_${pIdx}`}
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={`/c/${parent.slug}`}
              >
                {parent.name}
              </ListGroup.Item>) : ''}
            </ListGroup> : ''}
          </Col>
          <Col xs={12} md={6} lg={true} className="mb-4">
            <h5 className="text-secondary fs-6">
              <FontAwesomeIcon icon="building" className="text-secondary me-2"/>
              Information
              {isMobile ? <Button onClick={() => toggleShowHide('company')} variant={'link'} size={'sm'}
                                  className={'ms-4 transparent light border-none'}>
                <FontAwesomeIcon icon={showHideSection.company ? 'chevron-up' : 'chevron-down'}
                                 className={'grey-text text-darken-3'}/>
              </Button> : ''}
            </h5>
            {(isMobile && showHideSection.company) || !isMobile ? <ListGroup variant="flush">
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/about'}
              >
                About Us
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/contact'}
              >
                Contact Us
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/delivery'}
              >
                Delivery Information
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/selling-to-us'}
              >
                Selling to Us
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/payment'}
              >
                Payment
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/legal/returns'}
              >
                Returns & Exchanges
              </ListGroup.Item>
              <ListGroup.Item
                variant="tertiary"
                className={styles.listGroupItemTertiary}
                action
                as={Link}
                to={'/royal-mail-changes'}
              >
                Royal Mail Changes
              </ListGroup.Item>
            </ListGroup> : ''}
          </Col>
          <Col xs={12} md={6} lg={true} className="mb-4">
            <h5 className="text-secondary fs-6">
              <FontAwesomeIcon icon="user" className="text-secondary me-2"/>
              My Account
              {isMobile ? <Button onClick={() => toggleShowHide('resources')} variant={'link'} size={'sm'}
                                  className={'ms-4 transparent light border-none'}>
                <FontAwesomeIcon icon={showHideSection.resources ? 'chevron-up' : 'chevron-down'}
                                 className={'grey-text text-darken-3'}/>
              </Button> : ''}
            </h5>
            {(isMobile && showHideSection.resources) || !isMobile ? <ListGroup variant="flush">
              {isAuthenticated ? <>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/account'}
                >
                  Dashboard
                </ListGroup.Item>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/account/orders'}
                >
                  Order History
                </ListGroup.Item>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/account/wish-list'}
                >
                  Wish List
                </ListGroup.Item>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/account/addresses'}
                >
                  Manage Addresses
                </ListGroup.Item>
              </> : <>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/login'}
                >
                  Login
                </ListGroup.Item>
                <ListGroup.Item
                  variant="tertiary"
                  className={styles.listGroupItemTertiary}
                  action
                  as={Link}
                  to={'/register'}
                >
                  Create account
                </ListGroup.Item>
              </>}
            </ListGroup> : ''}
          </Col>
        </Row>
        <Row className={'mb-4'}>
          <Col xs={12} className={'text-center'}>
            <Button variant={'light'} size={'sm'} className={'me-3'}>
              <FontAwesomeIcon icon={'bags-shopping'} className={'text-success me-2'} />
              eCommerce Packaging
            </Button>
            <Button variant={'light'} size={'sm'} className={'me-3'}>
              <FontAwesomeIcon type={'brand'} icon={'amazon'} className={'orange-text text-lighten-1 me-2'} />
              Amazon APASS
            </Button>
            <Button variant={'light'} size={'sm'}>
              <FontAwesomeIcon type={'brand'} icon={'paypal'} className={'blue-text text-lighten-1 me-2'} />
              PayPal Branded
            </Button>
          </Col>
        </Row>
      </Container>
      <Row className={`${styles.bottom} grey lighten-3`}>
        <Col>
          <Container className={(isTablet ? 'w-100 m-0' : '') + ' pt-4 pb-4'}
                     style={isTablet ? {maxWidth: '100%'} : {}}>
            <Row className="pb-3">
              <Col xs={12} md={9} className="text-center text-md-start">
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} target="_blank"
                        rel="noreferrer" href="https://status.purpleio.uk/status/wholesalepackaging">
                  <FontAwesomeIcon icon={'history'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Status
                </Button>
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} as={Link}
                        to="/legal">
                  <FontAwesomeIcon icon={'gavel'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Legal
                </Button>
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} as={Link}
                        to="/legal/privacy">
                  <FontAwesomeIcon icon={'user-secret'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Privacy Policy
                </Button>
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} as={Link}
                        to="/legal/cookie">
                  <FontAwesomeIcon icon={'cookie'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Cookie Policy
                </Button>
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} as={Link}
                        to="/legal/terms">
                  <FontAwesomeIcon icon={'book'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Terms & Conditions
                </Button>
                <Button variant="grey-light" className={'m-1 grey-text darken-2'} size={'sm'} as={Link}
                        to="/sitemap">
                  <FontAwesomeIcon icon={'sitemap'} className={'text-secondary ' + (isMobile ? 'me-1' : 'me-2')}/>
                  Sitemap
                </Button>
              </Col>
              <Col xs={12} md={3} className="p-0 text-center text-md-end">
                <Button variant="outline" className="fs-5 text-primary" title="Facebook" target="_blank"
                        rel="noreferrer" href="https://www.facebook.com/WholesalePackaging">
                  <FontAwesomeIcon type="brand" icon="facebook"/>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-md-left lh-1">
                {seoKeywords.map((keyword, kIdx) => <Link
                  key={`footer_keyword_${kIdx}`}
                  to="/shop"
                  className={'fs-10 inline-block d-inline-block p-2 pt-0 pb-0 grey-text text-darken-1'}
                >
                  <small>{keyword}</small>
                </Link>)}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className={'grey darken-4 white-text'}>
        <Col className={'py-3 fs-8 text-center'}>
          Designed, Developed & Hosted by <a href={'https://purpleio.uk'} target={'_blank'} rel={'noreferrer'} className={'bolder text-secondary'}>Purple IO Ltd</a>.&nbsp;
          <br className={'d-md-none'} />
          Data Centre Based in Colchester, Essex, UK.
        </Col>
      </Row>
    </footer>
  </>;
};

export default Footer;
