import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Providers from './system/Providers';
import AuthenticatedRoutes from './system/AuthenticatedRoutes';
import ScrollToTop from './system/ScrollToTop';
import ReactGA from 'react-ga4';

import Layout from './pages/Layout';
import Loading from './pages/Loading';

const lazyRetry = function(componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-lazy-${name}-refreshed`) || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-lazy-${name}-refreshed`, 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem(`retry-lazy-${name}-refreshed`, 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

// Basic Pages
const Home = lazy(() => lazyRetry(() => import('./pages/Home'), 'Home'));
const About = lazy(() => lazyRetry(() => import('./pages/About'), 'About'));
const Contact = lazy(() => lazyRetry(() => import('./pages/Contact'), 'Contact'));

// Information Pages
const Delivery = lazy(() => lazyRetry(() => import('./pages/Delivery'), 'Delivery'));
const Payment = lazy(() => lazyRetry(() => import('./pages/Payment'), 'Payment'));
const Selling = lazy(() => lazyRetry(() => import('./pages/Selling'), 'Selling'));
const RoyalMail = lazy(() => lazyRetry(() => import('./pages/RoyalMail'), 'RoyalMail'));

// Catalogue Pages
const Shop = lazy(() => lazyRetry(() => import('./pages/Shop'), 'Shop'));
const Category = lazy(() => lazyRetry(() => import('./pages/Category'), 'Category'));
const EcoFriendly = lazy(() => lazyRetry(() => import('./pages/Category/EcoFriendly'), 'EcoFriendly'));
const Product = lazy(() => lazyRetry(() => import('./pages/Product'), 'Product'));
const Search = lazy(() => lazyRetry(() => import('./pages/Search'), 'Search'));
const Cart = lazy(() => lazyRetry(() => import('./pages/Cart'), 'Cart'));
const Checkout = lazy(() => lazyRetry(() => import('./pages/Checkout'), 'Checkout'));
const CheckoutThankYou = lazy(() => lazyRetry(() => import('./pages/Checkout/ThankYou'), 'CheckoutThankYou'));

// Legal Pages
const Legal = lazy(() => lazyRetry(() => import('./pages/Legal'), 'Legal'));
const LegalCookie = lazy(() => lazyRetry(() => import('./pages/Legal/Cookie'), 'LegalCookie'));
const LegalReturns = lazy(() => lazyRetry(() => import('./pages/Legal/Returns'), 'LegalReturns'));
const LegalTerms = lazy(() => lazyRetry(() => import('./pages/Legal/Terms'), 'LegalTerms'));
const LegalPrivacy = lazy(() => lazyRetry(() => import('./pages/Legal/Privacy'), 'LegalPrivacy'));
const LegalWebsiteSecurity = lazy(() => lazyRetry(() => import('./pages/Legal/WebsiteSecurity'), 'LegalWebsiteSecurity'));

// Account Pages
const Account = lazy(() => lazyRetry(() => import('./pages/Account'), 'Account'));
const AccountLogin = lazy(() => lazyRetry(() => import('./pages/Account/Login'), 'AccountLogin'));
const AccountRegister = lazy(() => lazyRetry(() => import('./pages/Account/Register'), 'AccountRegister'));
const AccountEdit = lazy(() => lazyRetry(() => import('./pages/Account/Edit'), 'AccountEdit'));
const AccountWishlist = lazy(() => lazyRetry(() => import('./pages/Account/Wishlist'), 'AccountWishlist'));
const AccountOrder = lazy(() => lazyRetry(() => import('./pages/Account/Order'), 'AccountOrder'));
const AccountOrderView = lazy(() => lazyRetry(() => import('./pages/Account/Order/View'), 'AccountOrderView'));
const AccountAddress = lazy(() => lazyRetry(() => import('./pages/Account/Address'), 'AccountAddress'));
const AccountNotification = lazy(() => lazyRetry(() => import('./pages/Account/Notification'), 'AccountNotification'));

// Error Pages
const NoPage = lazy(() => lazyRetry(() => import('./pages/NoPage'), 'NoPage'));

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  return <Providers>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <ScrollToTop />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route element={<AuthenticatedRoutes />}>
              <Route path="/account" element={<Account />} />
              <Route path="/account/edit" element={<AccountEdit />} />
              <Route path="/account/notifications" element={<AccountNotification />} />
              <Route path="/account/wishlist" element={<AccountWishlist />} />
              <Route path="/account/orders" element={<AccountOrder />} />
              <Route path="/account/order/*" element={<AccountOrderView />} />
              <Route path="/account/addresses" element={<AccountAddress />} />
            </Route>

            <Route path='/register' element={<AccountRegister />} />
            <Route path="/login" element={<AccountLogin />} />
            <Route path="/account/activation/failed" element={<AccountLogin />} />
            <Route path="/account/activation/activated" element={<AccountLogin />} />
            <Route path="/account/activation/complete" element={<AccountLogin />} />

            <Route path="/search" element={<Search />} />
            <Route path="/search/*" element={<Search />} />

            <Route path="/shop" element={<Shop />} />
            <Route path="/c/eco-friendly" element={<EcoFriendly />} />
            <Route path="/c/*" element={<Category />} />
            <Route path="/p/*" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/thank-you" element={<CheckoutThankYou />} />
            <Route path="/checkout/thank-you/*" element={<CheckoutThankYou />} />

            <Route path='/about' element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/royal-mail-changes" element={<RoyalMail />} />
            <Route path="/feedback" element={<Contact />} />
            <Route path="/report" element={<Contact />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/selling-to-us" element={<Selling />} />

            <Route path="/legal" element={<Legal />} />
            <Route path="/legal/cookie" element={<LegalCookie />} />
            <Route path="/legal/privacy" element={<LegalPrivacy />} />
            <Route path='/legal/returns' element={<LegalReturns />} />
            <Route path="/legal/terms" element={<LegalTerms />} />
            <Route path="/legal/website-security" element={<LegalWebsiteSecurity />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Providers>;
}

export default App;
